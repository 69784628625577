import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { useState } from 'react';
import { BillBoard, LargeRectangle, SmallRectangleThin } from './standard-ads';

type GameAdBillBoardProps = {
  path: string;
};

export const GameAdBillBoard = ({ path }: GameAdBillBoardProps) => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile && !isTalkTablet;
  const [isVisible, setIsVisible] = useState(false);
  const paddingAmount = isVisible ? (isDesktop ? 'py-5' : 'py-4') : '';
  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <BillBoard
          path={path}
          onFillChange={setIsVisible}
          custParams={{ position: 'billboard' }}
          isVideoAd={false}
        />
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <LargeRectangle
          path={path}
          onFillChange={setIsVisible}
          custParams={{ position: 'billboard' }}
          isVideoAd={false}
        />
      );
    }
    if (isMobile) {
      return (
        <SmallRectangleThin
          path={path}
          onFillChange={setIsVisible}
          custParams={{ position: 'billboard' }}
          isVideoAd={false}
        />
      );
    }
    return <></>;
  };
  return (
    <section
      data-testid="section__games-ad-abtf"
      className={`flex items-center justify-center ${paddingAmount}`}
    >
      {responsiveAd()}
    </section>
  );
};

export const GameAdLeaderboard = ({ path }: GameAdBillBoardProps) => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile && !isTalkTablet;
  const [isVisible, setIsVisible] = useState(false);
  const paddingAmount = isVisible ? (isDesktop ? 'py-5' : 'py-4') : '';
  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <LargeRectangle
          path={path}
          onFillChange={setIsVisible}
          custParams={{ position: 'leaderboard' }}
          isVideoAd={false}
        />
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <LargeRectangle
          path={path}
          onFillChange={setIsVisible}
          custParams={{ position: 'leaderboard' }}
          isVideoAd={false}
        />
      );
    }
    if (isMobile) {
      return (
        <SmallRectangleThin
          path={path}
          onFillChange={setIsVisible}
          custParams={{ position: 'leaderboard' }}
          isVideoAd={false}
        />
      );
    }
    return <></>;
  };
  return (
    <section
      data-testid="section__games-ad-abtf"
      className={`flex items-center justify-center ${paddingAmount}`}
    >
      {responsiveAd()}
    </section>
  );
};

import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Microcopy } from 'components/typography';
import { VideoCompanions } from '.';
import { MediumRectangle } from './standard-ads';

type AdCompanionProps = {
  path: string;
  videoAd?: {
    token: string;
    custParams?: { [key: string]: string };
  };
  onFillChange?: (filled: boolean) => void;
  isVideoAd?: boolean;
};

export const AdCompanion = ({
  path,
  videoAd,
  onFillChange,
  isVideoAd = true
}: AdCompanionProps) => {
  const [isVisible, setIsVisible] = useState(false);

  // Handle fill state change
  const handleFillChange = (filled: boolean) => {
    setIsVisible(filled);
    if (onFillChange) {
      onFillChange(filled);
    }
  };

  return (
    <div style={{ width: 300 }}>
      {isVisible && (
        <Microcopy size="s" className="mb-5 pr-5 text-center text-gray-900">
          <FormattedMessage defaultMessage="TED is supported by ads and partners" />
        </Microcopy>
      )}

      {isVideoAd ? (
        // Use video companion for video ads
        <VideoCompanions.MediumRectangle
          path={path}
          identifier="ad-companion"
          onFillChange={handleFillChange}
          videoAd={videoAd}
        />
      ) : (
        // Use standard ad for display ads
        <MediumRectangle
          path={path}
          onFillChange={handleFillChange}
          custParams={videoAd?.custParams}
          isVideoAd={false}
        />
      )}
    </div>
  );
};

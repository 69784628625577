import { getGPT } from './gpt';
import { getSpot, showSpot, subSpot } from './spots';
import { buildAdPath, stringifySize } from './utils';

export { getGPT, getSpot, showSpot, subSpot, buildAdPath, stringifySize };

/**
 * Sets page-level targeting parameters that apply to all ad slots
 * Must be called before enableServices()
 */
export async function setPageTargeting(
  targeting: Record<string, string | string[]>
): Promise<void> {
  const googletag = await getGPT();

  if (!googletag || !googletag.cmd) return;

  googletag.cmd.push(() => {
    const pubads = googletag.pubads?.();
    if (!pubads) return;

    Object.entries(targeting).forEach(([key, value]) => {
      pubads.setTargeting(key, value);
    });
  });
}

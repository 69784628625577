import cx from 'classnames';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { useState } from 'react';
import Trim from '../video-ads/trim';
import {
  BillBoard,
  LargeRectangle,
  MediumRectangle,
  SmallRectangle,
  SmallRectangleThin
} from './index';
export const HomeAdInlineATF = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile && !isTalkTablet;
  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <Trim filled>
          <LargeRectangle
            custParams={{ position: 'abovefold' }}
            path="/ted3/web/home/atf"
            isVideoAd={false}
          />
        </Trim>
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <Trim filled>
          <LargeRectangle
            custParams={{ position: 'abovefold' }}
            path="/ted3/web/home/atf"
            isVideoAd={false}
          />
        </Trim>
      );
    }
    if (isMobile) {
      return (
        <Trim filled>
          <SmallRectangleThin
            custParams={{ position: 'abovefold' }}
            path="/ted3/web/home/atf"
            isVideoAd={false}
          />
        </Trim>
      );
    }
    return <></>;
  };
  return responsiveAd();
};

export const HomeAdBilllBoardATF = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile && !isTalkTablet;
  const [isVisible, setIsVisible] = useState(false);
  const paddingAmount = isVisible ? (isDesktop ? 'py-5' : 'py-4') : '';
  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <BillBoard
          path="/ted3/web/home"
          onFillChange={setIsVisible}
          custParams={{ position: 'billboard' }}
          isVideoAd={false}
        />
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <LargeRectangle
          path="/ted3/web/home"
          onFillChange={setIsVisible}
          custParams={{ position: 'billboard' }}
          isVideoAd={false}
        />
      );
    }
    if (isMobile) {
      return (
        <SmallRectangle
          path="/ted3/web/home"
          onFillChange={setIsVisible}
          custParams={{ position: 'billboard' }}
          isVideoAd={false}
        />
      );
    }
    return <></>;
  };
  return (
    <section
      data-testid="section__homepage-ad-abtf"
      className={`flex flex-row items-center justify-center bg-adgray ${paddingAmount}`}
      onClick={() => {
        mixpanel.track('homepage_click', {
          current_url: window.location.href,
          title: 'ad-billboard',
          item_number: '',
          link_type: 'image',
          value: ''
        });
      }}
    >
      {responsiveAd()}
    </section>
  );
};

export const HomeAdInlineSky = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile || !isTalkTablet;

  const containerClass = cx('flex flex-row items-center justify-center', {
    'mb-12 mt-12': isMobile,
    'sticky top-0': !isMobile
  });

  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <MediumRectangle
          custParams={{ position: 'abovefold' }}
          path="/ted3/web/home"
          isVideoAd={false}
        />
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <MediumRectangle
          custParams={{ position: 'abovefold' }}
          path="/ted3/web/home"
          isVideoAd={false}
        />
      );
    }
    if (isMobile) {
      return (
        <MediumRectangle
          custParams={{ position: 'abovefold' }}
          path="/ted3/web/home"
          isVideoAd={false}
        />
      );
    }
    return <></>;
  };
  return (
    <section
      data-testid="section__topics-ad-header-atf"
      className={containerClass}
    >
      <Trim filled>{responsiveAd()}</Trim>
    </section>
  );
};

export const HomeAdInlineBTF = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile && !isTalkTablet;
  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <Trim filled>
          <MediumRectangle path="/ted3/web/home" isVideoAd={false} />
        </Trim>
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <Trim filled>
          <MediumRectangle path="/ted3/web/home" isVideoAd={false} />
        </Trim>
      );
    }
    if (isMobile) {
      return (
        <Trim filled>
          <MediumRectangle path="/ted3/web/home" isVideoAd={false} />
        </Trim>
      );
    }
    return <></>;
  };
  return responsiveAd();
};

import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { useState, useEffect, useMemo } from 'react';
import Trim from '../video-ads/trim';
import {
  BillBoard,
  LargeRectangle,
  Skyscraper,
  SmallRectangle,
  SmallRectangleThin
} from './standard-ads';
import { useRouter } from 'next/router';

// Helper to extract playlist slug from URL
const usePlaylistSlug = () => {
  const router = useRouter();

  return useMemo(() => {
    if (typeof window === 'undefined') return undefined;

    const path = window.location.pathname;
    const playlistMatch = /\/playlists\/\d+\/([^/]+)/.exec(path);

    if (playlistMatch && playlistMatch[1]) {
      return playlistMatch[1];
    }

    return undefined;
  }, [router.asPath]);
};

export const PlaylistAdBillboard = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile && !isTalkTablet;
  const [isVisible, setIsVisible] = useState(false);
  const paddingAmount = isVisible ? (isDesktop ? 'py-5' : 'py-4') : '';
  const playlistSlug = usePlaylistSlug();

  const custParams = useMemo(() => {
    const params: Record<string, string> = { position: 'billboard' };
    if (playlistSlug) {
      params.playlist = playlistSlug;
    }
    return params;
  }, [playlistSlug]);

  const responsiveAd = (setIsVisible: (visible: boolean) => void) => {
    if (isDesktop) {
      return (
        <BillBoard
          path="/ted3/web/playlists"
          custParams={custParams}
          onFillChange={setIsVisible}
          isVideoAd={false}
        />
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <LargeRectangle
          path="/ted3/web/playlists"
          custParams={custParams}
          onFillChange={setIsVisible}
          isVideoAd={false}
        />
      );
    }
    if (isMobile) {
      return (
        <SmallRectangle
          path="/ted3/web/playlists"
          custParams={custParams}
          onFillChange={setIsVisible}
          isVideoAd={false}
        />
      );
    }
    return <></>;
  };

  return (
    <section
      data-testid="section__playlist-ad-billboard"
      className={`flex flex-row items-center justify-center bg-gray-300 ${paddingAmount}`}
    >
      {responsiveAd(setIsVisible)}
    </section>
  );
};

export const PlaylistIndexAdInlineATF = () => {
  const isMobile = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'playlistAds'
  });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'playlistAds'
  });
  const isDesktop = !isMobile && !isTalkTablet;
  const [isVisible, setIsVisible] = useState(false);
  const playlistSlug = usePlaylistSlug();

  const custParams = useMemo(() => {
    const params: Record<string, string> = { position: 'abovefold' };
    if (playlistSlug) {
      params.playlist = playlistSlug;
    }
    return params;
  }, [playlistSlug]);

  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <Trim filled={isVisible}>
          <Skyscraper
            path="/ted3/web/playlists/atf"
            onFillChange={setIsVisible}
            custParams={custParams}
            isVideoAd={false}
          />
        </Trim>
      );
    }
    return <></>;
  };
  return (
    <section
      data-testid="section__playlists-ad-header-atf"
      className="-mt-8 flex flex-row items-center justify-center"
    >
      {responsiveAd()}
    </section>
  );
};

export const PlaylistIndexAdInlineATFMobile = () => {
  const isMobile = useIsBreakpointWidth({
    size: 'sm',
    breakPointType: 'playlistAds'
  });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'playlistAds'
  });
  const [isVisible, setIsVisible] = useState(false);
  const containerClass = isMobile
    ? 'mt-8 flex flex-row items-center justify-center'
    : 'mt-12 flex flex-row items-center justify-center';
  const playlistSlug = usePlaylistSlug();

  const custParams = useMemo(() => {
    const params: Record<string, string> = { position: 'abovefold' };
    if (playlistSlug) {
      params.playlist = playlistSlug;
    }
    return params;
  }, [playlistSlug]);

  const responsiveAd = () => {
    if (isTalkTablet && !isMobile) {
      return (
        <Trim filled={isVisible}>
          <LargeRectangle
            path="/ted3/web/playlists/atf"
            onFillChange={setIsVisible}
            custParams={custParams}
            isVideoAd={false}
          />
        </Trim>
      );
    }
    if (isMobile) {
      return (
        <Trim filled={isVisible}>
          <SmallRectangleThin
            path="/ted3/web/playlists/atf"
            onFillChange={setIsVisible}
            custParams={custParams}
            isVideoAd={false}
          />
        </Trim>
      );
    }
    return <></>;
  };
  return (
    <section
      data-testid="section__talkindex-ad-atf-mobile"
      className={containerClass}
    >
      {responsiveAd()}
    </section>
  );
};

export const PlaylistResultsAdInlineBTF = () => {
  const isMobile = useIsBreakpointWidth({ size: 'sm', breakPointType: 'ads' });
  const isTalkTablet = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'ads'
  });
  const isDesktop = !isMobile && !isTalkTablet;
  const playlistSlug = usePlaylistSlug();

  const custParams = useMemo(() => {
    const params: Record<string, string> = { position: 'belowfold' };
    if (playlistSlug) {
      params.playlist = playlistSlug;
    }
    return params;
  }, [playlistSlug]);

  const responsiveAd = () => {
    if (isDesktop) {
      return (
        <LargeRectangle
          path="/ted3/web/playlists/btf"
          custParams={custParams}
          isVideoAd={false}
        />
      );
    }
    if (isTalkTablet && !isMobile) {
      return (
        <LargeRectangle
          path="/ted3/web/playlists/btf"
          custParams={custParams}
          isVideoAd={false}
        />
      );
    }
    if (isMobile) {
      return (
        <SmallRectangleThin
          path="/ted3/web/playlists/btf"
          custParams={custParams}
          isVideoAd={false}
        />
      );
    }
    return <></>;
  };
  return (
    <div className="mb-10 mt-10 flex flex-row items-center justify-center">
      <Trim filled>{responsiveAd()}</Trim>
    </div>
  );
};

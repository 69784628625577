import {
  buildAdPath,
  getGPT,
  getSpot,
  showSpot,
  stringifySize,
  subSpot
} from 'lib/ads';
import { sendErrorToServices } from 'lib/logging';
import { useEffect, useRef, useState } from 'react';

// Helper to manage GPT's global video context
let prevVideoId: string;
async function setVideoContent(contentSourceId?: string, videoId?: string) {
  if (!contentSourceId || !videoId) return;
  if (videoId === prevVideoId) return;

  const googletag = await getGPT();
  prevVideoId = videoId;

  if (googletag && googletag.cmd) {
    googletag.cmd.push(() => {
      if (googletag.pubads) {
        googletag.pubads().setVideoContent(contentSourceId, videoId);
      }
    });
  }
}

// Helper to ensure tokens are used only once per spot
const usedTokens: string[] = [];
function registerToken(token: string, handle: string) {
  const tokenHandle = `${handle}:${token}`;
  const isRegistered = usedTokens.includes(tokenHandle);
  if (isRegistered) return false;
  usedTokens.push(tokenHandle);
  return true;
}

type VideoCompanionProps = {
  path: string;
  identifier: string;
  videoAd?: {
    token: string;
    contentSourceId?: string;
    videoId?: string;
    companions?: Array<Record<string, string>>;
    custParams?: Record<string, string>;
  };
  type: string;
  size: [number, number];
  onFillChange?: (filled: boolean) => void;
};

const VideoCompanion = ({
  path,
  identifier,
  videoAd,
  type,
  size,
  onFillChange
}: VideoCompanionProps) => {
  const el = useRef<null | HTMLDivElement>(null);
  const [filled, setFilled] = useState(false);

  // Handle for spot and token lookups
  const handle = `${type}:${path}:${identifier}`;

  // Convert size array to a string in the format "widthxheight"
  const sizeString = stringifySize(size);

  // This token changes only when we should render a new ad
  const videoAdToken = videoAd && videoAd.token;

  useEffect(() => {
    let unsubscribe: () => Promise<void>;
    let willDismount: boolean;

    async function setupVideoCompanion() {
      // If there's no token then there's nothing to render
      if (!videoAdToken) return;

      try {
        // Get googletag first
        const googletag = await getGPT();
        if (!googletag) return;

        // Global GPT setting to enforce content exclusions etc.
        await setVideoContent(videoAd?.contentSourceId, videoAd?.videoId);

        // If token can't be registered then it was already used;
        // nothing further can be done with this slot
        if (!registerToken(videoAdToken, handle)) return;

        // Ensure cmd exists
        googletag.cmd = googletag.cmd || [];
        googletag.cmd.push(async () => {
          if (willDismount) return;

          // Use a type-safe definer function that returns the expected googletag.Slot
          const spot = await getSpot(handle, ({ containerId }) => {
            if (!googletag || !googletag.defineSlot) {
              sendErrorToServices(
                'Google tag or defineSlot not available',
                'VideoCompanion'
              );
            }

            // Ensure we're returning a proper googletag.Slot
            const slot = googletag.defineSlot?.(
              buildAdPath(path),
              size,
              containerId || identifier
            );

            if (!slot) {
              sendErrorToServices('Failed to create ad slot', 'VideoCompanion');
              throw new Error('Failed to create ad slot');
            }

            // Safely access pubads with type checking
            if (!googletag.pubads || typeof googletag.pubads !== 'function') {
              sendErrorToServices(
                'Pubads function not available',
                'VideoCompanion'
              );
              throw new Error('Pubads function not available');
            }

            const pubadsService = googletag.pubads();
            if (!pubadsService) {
              sendErrorToServices(
                'Pubads service not available',
                'VideoCompanion'
              );
              throw new Error('Pubads service not available');
            }

            // Add services and configure the slot
            return slot
              .addService(pubadsService)
              .setCollapseEmptyDiv(true, true);
          });

          // Special handling for DAI companions
          // When present, DAI companions replace GPT ads
          const companionsObject = videoAd?.companions;
          const companion = companionsObject?.find(obj =>
            Object.prototype.hasOwnProperty.call(obj, sizeString)
          );
          const html = companion ? companion[sizeString] : undefined;

          if (html && googletag) {
            googletag.pubads?.().clear([spot.slot]);
            setFilled(true);
            if (el && el.current) el.current.appendChild(spot.container);
            spot.container.style.display = '';
            spot.container.innerHTML = html;
          }

          // The slot will be empty during the ad request
          setFilled(false);

          // Parse custParams if it's a string - use the centralized helper
          let parsedCustParams: Record<string, string> = {};
          if (videoAd?.custParams && typeof videoAd.custParams === 'object') {
            parsedCustParams = videoAd.custParams as Record<string, string>;
          }

          Object.entries(parsedCustParams).forEach(([key, value]) => {
            spot.slot.setTargeting(key, value);
          });

          // Remove HTML left over by DAI companions in past renders
          spot.container.innerHTML = '';

          // Subscribe and forward changes in the ad slot's fill status
          unsubscribe = await subSpot(spot, (newFilled: boolean) => {
            if (spot.container.parentElement === el.current) {
              setFilled(newFilled);
            } else {
              setFilled(false);
            }
          });

          // Display or refresh the slot
          await showSpot(spot, el.current);
        });
      } catch (error) {
        sendErrorToServices(
          `Error setting up video companion: ${error}`,
          'VideoCompanion'
        );
      }
    }

    setupVideoCompanion();

    return function cleanup() {
      willDismount = true;

      if (unsubscribe) unsubscribe();
    };

    // Should only be re-fired if the handle or token change:
  }, [handle, videoAdToken]);

  useEffect(() => {
    if (typeof onFillChange === 'function') onFillChange(filled);
  }, [filled, onFillChange]);

  return <div id={identifier} className="ted-ads-test-id" ref={el} />;
};
// TODO: Move this component into its own file and combine with video-ads components
const LARGE_RECTANGLE = 'VideoCompanions.LargeRectangleAd';
const MEDIUM_RECTANGLE = 'VideoCompanions.MediumRectangleAd';
const SMALL_RECTANGLE_THIN = 'VideoCompanions.SmallRectangleThinAd';
const BUTTON_2 = 'VideoCompanions.Button2Ad';

type VidoeCompanionTypesProps = Omit<VideoCompanionProps, 'size' | 'type'>;

/**
728x90 ("Large Rectangle") companion banner ad.
*/
export const LargeRectangle = (props: VidoeCompanionTypesProps) => {
  return <VideoCompanion {...props} type={LARGE_RECTANGLE} size={[728, 90]} />;
};

/**
300x250 ("Medium Rectangle") companion banner ad.
*/
export const MediumRectangle = (props: VidoeCompanionTypesProps) => {
  return (
    <VideoCompanion {...props} type={MEDIUM_RECTANGLE} size={[300, 250]} />
  );
};

/**
 * 320x50 ("Small Rectangle Thin") companion banner ad.
 */
export const SmallRectangleThin = (props: VidoeCompanionTypesProps) => {
  return (
    <VideoCompanion {...props} type={SMALL_RECTANGLE_THIN} size={[320, 50]} />
  );
};

// TODO: Move this component into its own file and combine with video-ads components
/**
120x60 ("Button 2") companion banner ad.
*/
export const Button2 = (props: VidoeCompanionTypesProps) => {
  return <VideoCompanion {...props} type={BUTTON_2} size={[120, 60]} />;
};

LargeRectangle.displayName = LARGE_RECTANGLE;
MediumRectangle.displayName = MEDIUM_RECTANGLE;
SmallRectangleThin.displayName = SMALL_RECTANGLE_THIN;
Button2.displayName = BUTTON_2;

/**
This library provides general-purpose banner ad components
i.e. components banner ads outside of video pages.
*/

import { useEffect, useRef, useState } from 'react';
import {
  buildAdPath,
  getGPT,
  getSpot,
  showSpot,
  subSpot
} from 'lib/ads';

type StandardAdProps = {
  path: string;
  type?: string;
  size?: googletag.GeneralSize;
  custParams?: Record<string, string>;
  onFillChange?: (filled: boolean) => void;
  isVideoAd?: boolean;
};

// Add a type guard to check if the size is a valid [number, number] tuple
const isValidAdSize = (
  size: googletag.GeneralSize
): size is [number, number] => {
  return (
    size.length === 2 &&
    Array.isArray(size) &&
    typeof size[0] === 'number' &&
    typeof size[1] === 'number'
  );
};

export const StandardAd = ({
  path,
  type,
  size,
  onFillChange,
  custParams,
  isVideoAd = false
}: StandardAdProps) => {
  const el = useRef(null);
  const [filled, setFilled] = useState(false);

  // Handle for spot lookups
  const handle = `${type}:${path}`;

  useEffect(() => {
    let unsubscribe: () => Promise<void>;
    let willDismount: boolean;

    async function setupStandardAd() {
      const googletag = await getGPT();

      if (!googletag || !googletag.cmd) return;

      googletag.cmd.push(async () => {
        if (willDismount) return;

        const spot = await getSpot(
          handle,
          ({ containerId }: { containerId: string }) => {
            if (!googletag || !googletag.defineSlot) return null;

            if (isValidAdSize(size || [])) {
              return googletag
                .defineSlot(
                  buildAdPath(path),
                  (size || []) as [number, number],
                  containerId
                )
                .addService(googletag.pubads?.() || null)
                .setCollapseEmptyDiv(true, true);
            } else {
              console.warn(`Invalid ad size format: ${size}`);
              // Handle invalid size case
              return null;
            }
          }
        );

        if (!spot) return;

        // The slot will be empty during the ad request
        setFilled(false);

        // Set slot targeting
        spot.slot.clearTargeting();

        // Apply custom parameters
        Object.entries(custParams || {}).forEach(([key, value]) =>
          spot.slot.setTargeting(key, value)
        );

        // Subscribe and forward changes in the ad slot's fill status
        unsubscribe = await subSpot(spot, newFilled => {
          if (spot.container.parentElement === el.current) setFilled(newFilled);
          else setFilled(false);
        });

        // Display or refresh the slot
        await showSpot(spot, el.current);
      });
    }

    setupStandardAd();

    return function cleanup() {
      // TODO: change this to a ref... Not sure what we're doing here, this variable will reset the next time the useEffect runs
      willDismount = true;
      // TODO: The unsubscribe has an await inside it, so we need to handle this better
      if (unsubscribe) unsubscribe();
    };

    // This effect should only be re-fired if the handle changes:
  }, [handle, isVideoAd]);

  useEffect(() => {
    if (typeof onFillChange === 'function') onFillChange(filled);
  }, [filled, onFillChange]);
  return (
    <div
      className="ted-ads-test-id"
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: `${size?.[0] || ''}px`,
        overflow: 'hidden'
      }}
      ref={el}
    />
  );
};
// TODO: Move this component into its own file
const MEDIUM_RECTANGLE = 'MediumRectangleAd';

/**
300x250 ("medium rectangle") banner ad.
*/
export const MediumRectangle = (props: StandardAdProps) => {
  return <StandardAd {...props} type={MEDIUM_RECTANGLE} size={[300, 250]} />;
};

MediumRectangle.displayName = MEDIUM_RECTANGLE;

const LARGE_RECTANGLE = 'LargeRectangleAd';
export const LargeRectangle = (props: StandardAdProps) => {
  return <StandardAd {...props} type={LARGE_RECTANGLE} size={[728, 90]} />;
};

const SMALL_RECTANGLE = 'SmallRectangleAd';
export const SmallRectangle = (props: StandardAdProps) => {
  return <StandardAd {...props} type={SMALL_RECTANGLE} size={[320, 100]} />;
};

const SMALL_RECTANGLE_THIN = 'SmallRectangleThinAd';
export const SmallRectangleThin = (props: StandardAdProps) => {
  return <StandardAd {...props} type={SMALL_RECTANGLE_THIN} size={[320, 50]} />;
};

const SKYSCRAPER = 'SKYSCRAPER';
export const Skyscraper = (props: StandardAdProps) => {
  return <StandardAd {...props} type={SKYSCRAPER} size={[300, 600]} />;
};

const RESPONSIVE_RECTANGLE = 'ResponsiveRectangleAd';
export const ResponsiveRectangle = (props: StandardAdProps) => {
  return <StandardAd {...props} type={RESPONSIVE_RECTANGLE} />;
};

const BILLBOARD = 'Billboard';
export const BillBoard = (props: StandardAdProps) => {
  return <StandardAd {...props} type={BILLBOARD} size={[970, 250]} />;
};
